<template>
  <b-card class="mb-3 card-custom-class">
    <b-row v-if="$store.state['address'].addressLoading">
      <b-col>
        <b-skeleton class="mb-2" animation="wave" width="85%"></b-skeleton>
        <b-skeleton class="mb-2" animation="wave" width="55%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-col>
    </b-row>
    <b-row v-if="!$store.state['address'].addressLoading">
      <b-col v-if="Object.keys(defaultAddress).length !== 0">
        <b-card-text class="address-main">
          Deliver to {{ defaultAddress.fullName }}
          <img
            @click="$bvModal.show('bv-modal-example')"
            class="float-right"
            style="cursor: pointer"
            src="img/address/EditIcon.png"
            alt=""
          />
        </b-card-text>
        <b-card-text class="address-details">
          <b-icon
            class="mr-1 mt-1"
            icon="geo-alt-fill"
            scale="1"
            variant="danger"
          ></b-icon>
          <p>
            {{ defaultAddress.street2 }}, {{ defaultAddress.city }},
            {{ defaultAddress.state }}, {{ defaultAddress.zip }}
          </p>
        </b-card-text>
      </b-col>
      <b-col class="text-center" v-else>
        <button
          @click="() => this.$bvModal.show(defaultAddress._id)"
          type="button"
          class="btn btn-outline-primary btn-lg mr-3"
        >
          <span class="button-text"></span> Add Address
        </button>
      </b-col>
      <template v-if="$options.components['address-cart-address-modal']">
        <address-cart-address-modal
          @selectedAddress="selectedAddress"
          @deleteAddress="deleteAddress"
          @editAddress="editAddress"
          :deliveryAddress="deliveryAddress"
        />
        <b-modal
          :id="defaultAddress._id"
          size="lg"
          :hide-footer="true"
          :hide-header="true"
          :no-close-on-esc="true"
          :no-close-on-backdrop="true"
          centered
          header-class="my-class"
          body-class="p-0"
        >
          <template v-if="$options.components['address-edit-address-modal']">
            <address-edit-address-modal ref="editAddressRef" />
          </template>
        </b-modal>
      </template>
    </b-row>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "cart-address",
  props: {
    callApi: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      deliveryAddress: (state) => state.address.deliveryAddress,
    }),
  },
  data() {
    return {
      popup: false,
    };
  },
  methods: {
    editAddress(e) {
      this.$refs.editAddressRef.hitEditData(e);
    },
    async deleteAddress(e) {
      console.log(e);
      await this.$store.dispatch("address/deleteAddress", e);
    },
    async selectedAddress(e) {
      this.$store.commit("address/updateDefaultData", e);
      // await this.$store.dispatch("address/fetchDeliveryAddress");
    },
  },
  async mounted() {
    if (this.callApi)
      await this.$store.dispatch("address/fetchDeliveryAddress");
  },
};
</script>
<style scoped>
.address-main {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 11px;
  text-transform: capitalize;
  color: #000000;
}
.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #9e9e9e;
  display: flex;
}
.text-danger {
  color: #fe7b08 !important;
}

.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
/*  */
.list-group-modal {
  margin-top: 55px;
  max-height: 440px;
  margin-left: 10px;
  margin-right: 4px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.change-location-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  color: #2e2e2e;
}
.add-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ff6a2e;
  float: right;
  cursor: pointer;
}
.address-name {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;

  color: #000000;
}
.body-class-modal {
  margin-top: -7px;
}
/*  */
.box-hovered {
  border: 2px solid var(--v-primary-base);
}
.address-details-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #2e2e2e;
  margin-left: 37px;
  margin-top: 4px;
}

.form-control {
  border: none;
}
.my-class {
  background: black;
  color: white;
}
.closemodel {
  position: absolute;
  top: 2px;
  right: -2px;
}

.default-address-class {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
  margin-left: 10px;
}
.btn-outline-primary {
  width: 180px;
}
.btn-primary {
  width: 180px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}
.no-delivery {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-transform: capitalize;
  color: #000000;
}
.no-delivery-p-tag {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
</style>
