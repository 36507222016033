var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-3 card-custom-class"
  }, [_vm.$store.state['address'].addressLoading ? _c('b-row', [_c('b-col', [_c('b-skeleton', {
    staticClass: "mb-2",
    attrs: {
      "animation": "wave",
      "width": "85%"
    }
  }), _c('b-skeleton', {
    staticClass: "mb-2",
    attrs: {
      "animation": "wave",
      "width": "55%"
    }
  }), _c('b-skeleton', {
    attrs: {
      "animation": "wave",
      "width": "70%"
    }
  })], 1)], 1) : _vm._e(), !_vm.$store.state['address'].addressLoading ? _c('b-row', [Object.keys(_vm.defaultAddress).length !== 0 ? _c('b-col', [_c('b-card-text', {
    staticClass: "address-main"
  }, [_vm._v(" Deliver to " + _vm._s(_vm.defaultAddress.fullName) + " "), _c('img', {
    staticClass: "float-right",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": "img/address/EditIcon.png",
      "alt": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('bv-modal-example');
      }
    }
  })]), _c('b-card-text', {
    staticClass: "address-details"
  }, [_c('b-icon', {
    staticClass: "mr-1 mt-1",
    attrs: {
      "icon": "geo-alt-fill",
      "scale": "1",
      "variant": "danger"
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.defaultAddress.street2) + ", " + _vm._s(_vm.defaultAddress.city) + ", " + _vm._s(_vm.defaultAddress.state) + ", " + _vm._s(_vm.defaultAddress.zip) + " ")])], 1)], 1) : _c('b-col', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary btn-lg mr-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.show(_vm.defaultAddress._id);
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Add Address ")])]), _vm.$options.components['address-cart-address-modal'] ? [_c('address-cart-address-modal', {
    attrs: {
      "deliveryAddress": _vm.deliveryAddress
    },
    on: {
      "selectedAddress": _vm.selectedAddress,
      "deleteAddress": _vm.deleteAddress,
      "editAddress": _vm.editAddress
    }
  }), _c('b-modal', {
    attrs: {
      "id": _vm.defaultAddress._id,
      "size": "lg",
      "hide-footer": true,
      "hide-header": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
      "centered": "",
      "header-class": "my-class",
      "body-class": "p-0"
    }
  }, [_vm.$options.components['address-edit-address-modal'] ? [_c('address-edit-address-modal', {
    ref: "editAddressRef"
  })] : _vm._e()], 2)] : _vm._e()], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }